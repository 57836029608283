import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './css/fonts.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';
import ErrorBoundary from './components/ErrorBoundary';

// Add these two lines for ReactQuill and react-toastify styles
import 'react-quill/dist/quill.snow.css';
import 'react-toastify/dist/ReactToastify.css';

// Suppress DOMNodeInserted deprecation warning
const originalConsoleWarn = console.warn;
console.warn = function(...args) {
  if (args[0].includes('DOMNodeInserted')) return;
  originalConsoleWarn.apply(console, args);
};

// Get the root element
const container = document.getElementById('root');

// Create a root
const root = ReactDOM.createRoot(container);

// Render the App component inside the root
root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
